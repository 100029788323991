import { ModuleMetadataSymbol } from './symbols'
import _ from 'lodash'
import {
	ContainerModuleLoader,
	Dependencies,
	FactoryWithDependencies,
	Identifier,
	IocContainer,
	ProviderCreator,
	TargetName,
} from './types'

type ContainerState = {
	instances: Record<Identifier, any>
	factories: Record<Identifier, any>
	namedFactories: Record<Identifier, any>
	namedInstances: Record<Identifier, any>
	instanceCache: Record<string, any>
}

export function Container(parentContainerState?: ContainerState | undefined): IocContainer {
	const state: ContainerState = {
		instances: {},
		factories: {},
		namedFactories: {},
		namedInstances: {},
		instanceCache: {},
	}
	let container: IocContainer | undefined // eslint-disable-line prefer-const

	function getDependencyData(depObject: Dependencies) {
		const name = _.get(depObject, 'identifier') || depObject
		const targetName = _.get(depObject, 'name')
		const isMulti = !!_.get(depObject, 'multi')
		const isOptional = !!_.get(depObject, 'optional')
		return {
			isOptional,
			isMulti,
			name,
			targetName,
		}
	}

	function getInstances(name: Identifier, targetName: TargetName, containerState: ContainerState) {
		const instanceData = containerState.instances[name]

		if (!instanceData && !targetName) {
			return init(name, targetName, containerState)
		}
		if (targetName) {
			const namedValue = containerState.namedInstances[name]?.[targetName]
			if (!namedValue) {
				return init(name, targetName, containerState)
			}
			return namedValue
		}
		return instanceData
	}

	function resolveDependency(depObject: Dependencies, moduleName: Identifier) {
		const { name, isMulti, isOptional, targetName } = getDependencyData(depObject)
		const instances1 = getInstances(name, targetName, state)
		const instances2 = !instances1 && parentContainerState && getInstances(name, targetName, parentContainerState)
		const instances = instances1 || instances2 || []

		if (!isMulti && !isOptional && instances.length === 0) {
			throw new Error(`Unbound dependency ${name.toString()} in module ${moduleName.toString()}`)
		}
		if (!isMulti && instances.length > 1) {
			throw new Error('Cannot get multiple instances without requesting multi')
		}

		const result = isMulti ? instances : instances[0]
		return result
	}

	function init(name: Identifier, targetName: TargetName, containerState: ContainerState) {
		const modules = targetName ? containerState.namedFactories[name]?.[targetName] : containerState.factories[name]
		if (!modules) {
			return undefined
		}
		const allInstances = modules.map(
			(mod: {
				deps: Array<Dependencies>
				factory: FactoryWithDependencies
				provider: boolean
				factoryId: string
			}) => {
				const { deps, factory, provider, factoryId } = mod
				const initFactory = () =>
					provider
						? factory(container)
						: factory(...deps.map((d: Dependencies) => resolveDependency(d, name)))
				const instance = containerState.instanceCache[factoryId] || initFactory()
				containerState.instanceCache[factoryId] = instance

				if (targetName) {
					containerState.namedInstances[name] = containerState.namedInstances[name] || {}
					containerState.namedInstances[name][targetName] =
						containerState.namedInstances[name][targetName] || []
					containerState.namedInstances[name][targetName].push(instance)
				} else {
					containerState.instances[name] = containerState.instances[name] || []
					containerState.instances[name].push(instance)
				}
				return instance
			}
		)

		return allInstances
	}

	function registerProvider(name: Identifier, factory: ProviderCreator, factoryId: string) {
		state.factories[name] = state.factories[name] || []
		state.factories[name].push({ factory, deps: [], provider: true, factoryId })
	}
	function register(name: Identifier, factory: FactoryWithDependencies, factoryId: string) {
		const deps = factory[ModuleMetadataSymbol].dependencies
		state.factories[name] = state.factories[name] || []
		state.factories[name].push({ factory, deps, factoryId })
	}
	function registerWithTargetName(
		name: Identifier,
		factory: FactoryWithDependencies,
		targetName: TargetName,
		factoryId: string
	) {
		const deps = factory[ModuleMetadataSymbol].dependencies
		state.namedFactories[name] = state.namedFactories[name] || {}
		state.namedFactories[name][targetName] = state.namedFactories[name][targetName] || []
		state.namedFactories[name][targetName].push({ factory, deps, factoryId })
	}
	function registerConstantValueWithTargetName(
		name: Identifier,
		value: any,
		targetName: TargetName,
		factoryId: string
	) {
		state.namedFactories[name] = state.namedFactories[name] || {}
		state.namedFactories[name][targetName] = state.namedFactories[name][targetName] || []
		state.namedFactories[name][targetName].push({ factory: () => value, deps: [], factoryId })
	}

	function registerConstantValue(name: Identifier, value: any, factoryId: string) {
		state.factories[name] = state.factories[name] || []
		state.factories[name].push({ factory: () => value, deps: [], factoryId })
	}

	function unregister(name: Identifier) {
		delete state.factories[name]
		delete state.instances[name]
		delete state.namedFactories[name]
		delete state.namedInstances[name]
	}

	function bind(...moduleNames: Array<Identifier>) {
		return {
			to(factory: FactoryWithDependencies) {
				const factoryId = _.uniqueId()
				moduleNames.forEach((name) => register(name, factory, factoryId))
				return {
					whenTargetNamed(targetName: TargetName) {
						registerWithTargetName(
							moduleNames[0],
							factory,
							targetName,
							`${factoryId}_${targetName.toString()}`
						)
					},
				}
			},
			toProvider(factory: ProviderCreator) {
				const factoryId = _.uniqueId()
				registerProvider(moduleNames[0], factory, factoryId)
				return {
					whenTargetNamed(targetName: TargetName) {
						throw new Error(
							`calling whenTargetNamed ${targetName.toString()} with toProvider on module ${moduleNames[0].toString()} is not supported`
						)
					},
				}
			},
			toConstantValue(value: any) {
				const factoryId = _.uniqueId()
				registerConstantValue(moduleNames[0], value, factoryId)
				return {
					whenTargetNamed(targetName: TargetName) {
						registerConstantValueWithTargetName(
							moduleNames[0],
							value,
							targetName,
							`${factoryId}_${targetName.toString()}`
						)
					},
				}
			},
		}
	}

	function rebind(...moduleNames: Array<Identifier>) {
		moduleNames.forEach((name: Identifier) => unregister(name))
		return bind(...moduleNames)
	}

	function createChild() {
		return Container(state)
	}

	const api: IocContainer = {
		bind,
		rebind,
		getNamed(name: Identifier, targetName: string) {
			return resolveDependency({ identifier: name, name: targetName }, name)
		},
		getAll(name: Identifier) {
			return resolveDependency({ identifier: name, multi: true }, name)
		},
		get(name: Identifier) {
			return resolveDependency({ identifier: name, optional: true }, name)
		},
		load(...moduleLoaders: Array<ContainerModuleLoader>) {
			moduleLoaders.forEach((loader) => {
				loader(bind)
			})
		},
		createChild,
	}
	container = api
	return api
}
